import moment from 'moment'
import { useEffect, useState } from 'react'

import './clock.css'
import Switch from 'components/switch'
import Meta from 'components/common/Meta'

export default function Clock() {
  const initDarkMode = localStorage.getItem('dark-mode') === 'true'

  const [time, setTime] = useState(moment())
  const [isDarkMode, setIsDarkMode] = useState(initDarkMode)

  const deg = 6
  const minute = time.minutes() * deg
  const second = time.seconds() * deg
  const hour = time.format('h') * 30 + minute / deg / 2

  useEffect(() => {
    const interval = setInterval(() => setTime(moment()))
    return () => clearInterval(interval)
  }, [])

  const handleDarkMode = () => {
    localStorage.setItem('dark-mode', !isDarkMode)
    setIsDarkMode(!isDarkMode)
  }

  return (
    <div className="clock-wrapper">
      <Meta
        time={time}
        isDarkMode={isDarkMode}
        title="Analog Clock UI"
        description="Analog Clock UI with Dark and Light Mode."
      />
      <Switch isDarkMode={isDarkMode} handleDarkMode={handleDarkMode} />
      <div className="clock">
        <div className="hour">
          <div className="hr" style={{ transform: `rotateZ(${hour}deg)` }} />
        </div>
        <div className="minute">
          <div className="min" style={{ transform: `rotateZ(${minute}deg)` }} />
        </div>
        <div className="second">
          <div className="sec" style={{ transform: `rotateZ(${second}deg)` }} />
        </div>
      </div>
    </div>
  )
}
