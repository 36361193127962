import { Route, Routes } from 'react-router-dom'

import Clock from 'modules/Clock'
import HanumanChalisa from 'modules/HanumanChalisa'

function App() {
  return (
    <Routes>
      <Route path="/" element={<HanumanChalisa />} />
      <Route path="/clock" element={<Clock />} />
    </Routes>
  );
}

export default App;
