import { Helmet } from 'react-helmet'
import './HanumanChalisa.css'

function HanumanChalisa() {
  return (
    <>
      <Helmet>
        <title>श्री हनुमान चालीसा - Hanuman Chalisa Hindi | Beeing Parth</title>
        <body className="HanumanChalisa" />
        <link
          rel="icon"
          href="https://images.unsplash.com/photo-1583089892943-e02e5b017b6a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1760&q=80"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=2.0, minimum-scale=1, user-scalable=yes"
        />
        <meta name="language" content="hi" />
        <meta name="Distribution" content="Global" />
        <meta name="copyright" content="www.beeingparth.com" />
        <meta name="author" content="beeingparth" />
        <meta name="robots" content="index, follow, noodp, noydir" />
        <meta
          name="description"
          content="श्रीगुरु चरन सरोज रज, निज मनु मुकुरु सुधारि। बरनऊं रघुबर बिमल जसु, जो दायकु फल चारि।। बुद्धिहीन तनु जानिके, सुमिरौं पवन-कुमार।
बल बुद्धि बिद्या देहु मोहिं, हरहु कलेस बिकार।।"
        />
        <meta
          name="keywords"
          content="Hanuman Chalisa Hindi, श्री हनुमान चालीसा, Hanuman Chalisa Full, श्रीगुरु चरन सरोज रज, निज मनु मुकुरु सुधारि, हनुमान, बजरंगबली, पवनपुत्र, संकटमोचन, हनुमान महिमा, Shri Hanuman Chalisa in Hindi"
        />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="श्री हनुमान चालीसा। Hanuman Chalisa Hindi"
        />
        <meta property="og:url" content="https://beeingparth.com" />
        <meta
          property="og:description"
          content="श्रीगुरु चरन सरोज रज, निज मनु मुकुरु सुधारि। बरनऊं रघुबर बिमल जसु, जो दायकु फल चारि।। बुद्धिहीन तनु जानिके, सुमिरौं पवन-कुमार।
बल बुद्धि बिद्या देहु मोहिं, हरहु कलेस बिकार।।"
        />
        <meta
          property="og:image"
          content="https://images.unsplash.com/photo-1583089892943-e02e5b017b6a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1760&q=80"
        />
        <meta property="og:image:width" content="1000" />
        <meta property="og:image:height" content="1000" />
        <meta property="twitter:card" content="summary" />
        <meta
          property="twitter:title"
          content="श्री हनुमान चालीसा। Hanuman Chalisa Hindi"
        />
        <meta
          property="twitter:description"
          content="श्रीगुरु चरन सरोज रज, निज मनु मुकुरु सुधारि। बरनऊं रघुबर बिमल जसु, जो दायकु फल चारि।। बुद्धिहीन तनु जानिके, सुमिरौं पवन-कुमार।
बल बुद्धि बिद्या देहु मोहिं, हरहु कलेस बिकार।।"
        />
      </Helmet>
      <div>
        <h1>श्री हनुमान चालीसा</h1>
        <br />
        <img
          alt="श्री हनुमान चालीसा"
          src="https://images.unsplash.com/photo-1583089892943-e02e5b017b6a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1760&q=80"
        />
        <br />
        <br />
        <div>
          <strong>दोहा :</strong>
        </div>
        <br />
        <div>श्रीगुरु चरन सरोज रज, निज मनु मुकुरु सुधारि।</div>
        <div>बरनऊं रघुबर बिमल जसु, जो दायकु फल चारि।।</div>
        <br />
        <div>बुद्धिहीन तनु जानिके, सुमिरौं पवन-कुमार।</div>
        <div>बल बुद्धि बिद्या देहु मोहिं, हरहु कलेस बिकार।।</div>
        <br />
        <div>
          <strong>चौपाई :</strong>
        </div>
        <br />
        <div>
          जय&nbsp;
          <a
            target="_blank"
            rel="noreferrer"
            className="App-link"
            href="https://en.wikipedia.org/wiki/Hanuman"
          >
            हनुमान
          </a>
          &nbsp;ज्ञान गुन सागर।
        </div>
        <div>जय कपीस तिहुं लोक उजागर।।</div>
        <br />
        <div>रामदूत अतुलित बल धामा।</div>
        <div>अंजनि-पुत्र पवनसुत नामा।।</div>
        <br />
        <div>महाबीर बिक्रम बजरंगी।</div>
        <div>कुमति निवार सुमति के संगी।।</div>
        <br />
        <div>कंचन बरन बिराज सुबेसा।</div>
        <div>कानन कुंडल कुंचित केसा।।</div>
        <br />
        <div>हाथ बज्र औ ध्वजा बिराजै।</div>
        <div>कांधे मूंज जनेऊ साजै।</div>
        <br />
        <div>संकर सुवन केसरीनंदन।</div>
        <div>तेज प्रताप महा जग बन्दन।।</div>
        <br />
        <div>विद्यावान गुनी अति चातुर।</div>
        <div>राम काज करिबे को आतुर।।</div>
        <br />
        <div>प्रभु चरित्र सुनिबे को रसिया।</div>
        <div>राम लखन सीता मन बसिया।।</div>
        <br />
        <div>सूक्ष्म रूप धरि सियहिं दिखावा।</div>
        <div>बिकट रूप धरि लंक जरावा।।</div>
        <br />
        <div>भीम रूप धरि असुर संहारे।</div>
        <div>रामचंद्र के काज संवारे।।</div>
        <br />
        <div>लाय सजीवन लखन जियाये।</div>
        <div>श्रीरघुबीर हरषि उर लाये।।</div>
        <br />
        <div>रघुपति कीन्ही बहुत बड़ाई।</div>
        <div>तुम मम प्रिय भरतहि सम भाई।।</div>
        <br />
        <div>सहस बदन तुम्हरो जस गावैं।</div>
        <div>अस कहि श्रीपति कंठ लगावैं।।</div>
        <br />
        <div>सनकादिक ब्रह्मादि मुनीसा।</div>
        <div>नारद सारद सहित अहीसा।।</div>
        <br />
        <div>जम कुबेर दिगपाल जहां ते।</div>
        <div>कबि कोबिद कहि सके कहां ते।।</div>
        <br />
        <div>तुम उपकार सुग्रीवहिं कीन्हा।</div>
        <div>राम मिलाय राज पद दीन्हा।।</div>
        <br />
        <div>तुम्हरो मंत्र बिभीषन माना।</div>
        <div>लंकेस्वर भए सब जग जाना।।</div>
        <br />
        <div>जुग सहस्र जोजन पर भानू।</div>
        <div>लील्यो ताहि मधुर फल जानू।।</div>
        <br />
        <div>प्रभु मुद्रिका मेलि मुख माहीं।</div>
        <div>जलधि लांघि गये अचरज नाहीं।।</div>
        <br />
        <div>दुर्गम काज जगत के जेते।</div>
        <div>सुगम अनुग्रह तुम्हरे तेते।।</div>
        <br />
        <div>राम दुआरे तुम रखवारे।</div>
        <div>होत न आज्ञा बिनु पैसारे।।</div>
        <br />
        <div>सब सुख लहै तुम्हारी सरना।</div>
        <div>तुम रक्षक काहू को डर ना।।</div>
        <br />
        <div>आपन तेज सम्हारो आपै।</div>
        <div>तीनों लोक हांक तें कांपै।।</div>
        <br />
        <div>भूत पिसाच निकट नहिं आवै।</div>
        <div>महाबीर जब नाम सुनावै।।</div>
        <br />
        <div>नासै रोग हरै सब पीरा।</div>
        <div>जपत निरंतर हनुमत बीरा।।</div>
        <br />
        <div>संकट तें हनुमान छुड़ावै।</div>
        <div>मन क्रम बचन ध्यान जो लावै।।</div>
        <br />
        <div>सब पर राम तपस्वी राजा।</div>
        <div>तिन के काज सकल तुम साजा।</div>
        <br />
        <div>और मनोरथ जो कोई लावै।</div>
        <div>सोइ अमित जीवन फल पावै।।</div>
        <br />
        <div>चारों जुग परताप तुम्हारा।</div>
        <div>है परसिद्ध जगत उजियारा।।</div>
        <br />
        <div>साधु-संत के तुम रखवारे।</div>
        <div>असुर निकंदन राम दुलारे।।</div>
        <br />
        <div>अष्ट सिद्धि नौ निधि के दाता।</div>
        <div>अस बर दीन जानकी माता।।</div>
        <br />
        <div>राम रसायन तुम्हरे पासा।</div>
        <div>सदा रहो रघुपति के दासा।।</div>
        <br />
        <div>तुम्हरे भजन राम को पावै।</div>
        <div>जनम-जनम के दुख बिसरावै।।</div>
        <br />
        <div>अन्तकाल रघुबर पुर जाई।</div>
        <div>जहां जन्म हरि-भक्त कहाई।।</div>
        <br />
        <div>और देवता चित्त न धरई।</div>
        <div>हनुमत सेइ सर्ब सुख करई।।</div>
        <br />
        <div>संकट कटै मिटै सब पीरा।</div>
        <div>जो सुमिरै हनुमत बलबीरा।।</div>
        <br />
        <div>जै जै जै हनुमान गोसाईं।</div>
        <div>कृपा करहु गुरुदेव की नाईं।।</div>
        <br />
        <div>जो सत बार पाठ कर कोई।</div>
        <div>छूटहि बंदि महा सुख होई।।</div>
        <br />
        <div>जो यह पढ़ै हनुमान चालीसा।</div>
        <div>होय सिद्धि साखी गौरीसा।।</div>
        <br />
        <div>तुलसीदास सदा हरि चेरा।</div>
        <div>कीजै नाथ हृदय मंह डेरा।।</div>
        <br />
        <div>
          <strong>दोहा :</strong>
        </div>
        <br />
        <div>
          <strong>पवन तनय संकट हरन, मंगल मूरति रूप।</strong>
        </div>
        <div>
          <strong>राम लखन सीता सहित, हृदय बसहु सुर भूप।।</strong>
        </div>
        <br />
      </div>
    </>
  )
}

export default HanumanChalisa
