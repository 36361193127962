import Helmet from 'react-helmet'

import ShareBanner from 'assets/images/ShareBanner.png'
import DarkClock from 'assets/images/favicon/DarkClock.png'
import LightClock from 'assets/images/favicon/LightClock.png'

export default function Meta(props) {
  const { title = "", description = "", isDarkMode, time, children } = props

  return (
    <Helmet>
      {/* Meta Title */}
      <title>{time.format('LTS')} - {title}</title>
      <meta name="title" content={title} />
      <meta property="og:title" content={title} />
      <meta name="twitter:title" content={title} />

      {/* Meta Description */}
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta name="twitter:description" content={description} />

      {/* FACEBOOK */}
      <meta property="og:url" content="https://beeingparth.com/" />
      <meta property="og:image" content={ShareBanner} />

      {/* TWITTER */}
      <meta name="twitter:url" content="https://beeingparth.com" />
      <meta name="twitter:image" content={ShareBanner} />

      {/* Meta Keywords */}
      <meta name="keywords" content="ReactJs, Clock, Analog Clock, Analog Clock UI, UI, CSS, Designs, darkmode, lightmode" />

      {/* favicon */}
      <link rel="icon" href={isDarkMode ? DarkClock : LightClock} />

      {/* Body */}
      <body className={isDarkMode ? "dark-mode" : "light-mode"} />
      {children}
    </Helmet>
  )
}
